/* Global styles */
body,
h1,
h2,
p {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
   {
    /*background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)),
                  url("./imgs/integrations2.webp");*/
  }
}

.tac-one-regular,
.bebass-neue-regular,
.roboto-regular {
  font-weight: 400;
  font-style: normal;
}

/* Adjust specific font-families for unique classes */
.tac-one-regular {
  font-family: "Tac One", sans-serif;
  font-weight: 800;
}
.bebas-neue-regular {
  font-family: "Bebas Neue", sans-serif;
  font-weight: 600;
}

/* Hero section */
.hero-section {
  background: url("imgs/tampa-skyline3.png") no-repeat center center/cover;
  text-align: center;
  padding: 30px 10px;
  color: white;
  font-family: "Tac One", sans-serif;
  position: relative;
  background-position: center bottom;
  width: 100%;
}

.hero-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.hero-section * {
  position: relative;
  z-index: 2;
}

.hero-section h1 {
  font-size: 4vw;
  font-weight: 700;
}

.hero-section p {
  font-size: 2vw;
}

.hero-section img {
  max-width: 50%;
  height: auto;
}

/* Services section */
.services {
  display: flex;
  justify-content: space-around;
  text-align: center;
  padding: 20px;
  width: max;
  box-sizing: border-box; /* Include padding in the element's total width */
}

.service,
.service2 {
  width: 30%;
  padding: 20px;
  /*margin: 10px;*/
  margin: 0 auto; /* Center align the section */
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  background-color: #746f6a;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  color: #ffffff;
  border: 1px solid #ccc;
}

.integrations {
  background-color: #ffffff;
  padding: 10px;
  text-align: center;
  font-size: 1vw;
  /* border: 1px solid #ccc;*/
  /*background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)),
                  url("./imgs/integrations2.webp");*/
}

.service2header {
  background-color: #ffffff;
  /* Other styles remain the same as .service2 */
}

.service2 ul {
  list-style-type: none;
}

.service img,
.myFace {
  max-width: 100%;
  height: auto;
}

.medpic {
  align-content: left;
  opacity: 0.1;
  float: left;
  overflow: hidden;
}

.service h2,
.about h2 {
  color: #333;
  margin: 20px 0;
}

/* About section */
.about {
  background-color: #f5f5f5;
  padding: 20px;
  text-align: center;
}

.about p {
  margin-bottom: 20px;
}

/* Buttons */
button {
  background-color: #0056b3;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 10px;
  margin: 20px;
  font-size: 2vw;
  border-radius: 10px;
}

button:hover {
  opacity: 0.8;
}

/* Media Queries for smaller screens */
@media (max-width: 768px) {
  .hero-section h1 {
    font-size: 6vw; /* Larger font size for better readability on small screens */
  }

  .hero-section p,
  .service h2,
  .about h2 {
    font-size: 4vw; /* Adjust text size for readability */
  }

  .hero-section img {
    max-width: 80%;
  }

  .services,
  .service,
  .service2 {
    flex-direction: column; /* Stack services vertically */
    width: 100%; /* Full width for each service */
    text-align: center; /* Center align service text */
  }

  .service img,
  .myFace {
    max-width: 90%; /* Adjust image sizes */
  }
}
.styled-table {
  border-collapse: collapse;
  /*margin: 25px 0;*/
  font-size: 0.9em;
  min-width: 300px;
  width: 98%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  margin: 0 auto; /* Center align the section */
}

.styled-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}



code {
  background-color: #f4f4f4;
  padding: 2px 4px;
  border-radius: 4px;
}
.note {
  background-color: #fdf6e3;
  border-left: 5px solid #e67e22;
  padding: 10px;
  margin: 15px 0;
}